import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import BigNumber from "bignumber.js";
import React from "react";
import { Trans } from "react-i18next";

import AccountLink from "../../../../../components/AccountLink/AccountLink";
import Asset from "../../../../../components/Asset";

type PaymentProps = {
  operation: ServerApi.OperationRecord;
};

const Payment = (p: PaymentProps) => {
  if (
    p.operation.type !== "path_payment_strict_send" &&
    p.operation.type !== "path_payment_strict_receive" &&
    p.operation.type !== "payment"
  )
    return <></>;

  return (
    <>
      <Trans
        i18nKey="operation.payment"
        values={{
          amount: new BigNumber(p.operation.amount).toFixed().toString(),
        }}
        components={{
          asset: (
            <Asset
              code={p.operation.asset_code}
              issuerAddress={p.operation.asset_issuer}
              type={p.operation.asset_type}
            />
          ),
          recipient: <AccountLink accountAddress={p.operation.to} variant="text" />,
        }}
      />
      {p.operation.type !== "payment" && (
        <Trans
          i18nKey="operation.payment.path"
          values={{
            amount: new BigNumber(p.operation.source_amount).toString(),
          }}
          components={{
            asset: (
              <Asset
                code={p.operation.source_asset_code}
                issuerAddress={p.operation.source_asset_issuer}
                type={p.operation.source_asset_type}
              />
            ),
            recipient: <AccountLink accountAddress={p.operation.to} variant="text" />,
          }}
        />
      )}
    </>
  );
};

export default Payment;
