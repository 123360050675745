import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";

import { useStellarContext } from "../../contexts/StellarContextProvider";
import { getSourceAddress, shortenString } from "../../utils/links";
import AccountLink from "../AccountLink/AccountLink";
import CustomInternalLink from "../CustomInternalLink/CustomInternalLink";
import JsonButton from "../JSONButton/JSONButton";
import OperationTitle from "../OperationTitle/OperationTitle";
import { ListContainer, ListRow, TableTitles } from "../ReusableStyles";
import * as S from "./OperationsTable.styles";

type OperationsTableProps = {
  operations?: ServerApi.CollectionPage<ServerApi.OperationRecord>;
  expanded?: boolean;
  hideTransaction?: boolean;
  hideType?: boolean;
  showJson?: boolean;
};

const OperationsTable = (p: OperationsTableProps) => {
  const { environment } = useParams<{ environment: string }>();
  const { screenWidth } = useStellarContext();

  const { t } = useTranslation();

  return (
    <>
      <TableTitles>
        <S.AccountText>{t("account")}</S.AccountText>
        <S.OperationText>{t("operation")}</S.OperationText>
        {p.expanded && !p.hideTransaction && screenWidth > 560 && (
          <S.TransactionText>{t("transaction")}</S.TransactionText>
        )}
        {p.expanded && !p.hideType && screenWidth > 680 && <S.TypeText>{t("type")}</S.TypeText>}
        {(screenWidth > 400 || !p.showJson) && <S.TimeText>{t("time")}</S.TimeText>}
        {p.showJson && <S.JsonText>{screenWidth < 510 ? t("json") : t("json.file")}</S.JsonText>}
      </TableTitles>
      <ListContainer>
        {p.operations?.records.map((operation, index) => {
          const sourceAddress = getSourceAddress(operation);

          return (
            <ListRow $isLast={index === (p.operations?.records.length || 0) - 1} key={operation.id}>
              <S.AccountButtonWrapper>
                {operation.type === "account_merge" ? (
                  <span title={sourceAddress}>{sourceAddress.slice(0, 4)}</span>
                ) : (
                  <AccountLink accountAddress={sourceAddress} variant="button" />
                )}
              </S.AccountButtonWrapper>
              <S.TitleWrapper>
                <OperationTitle operation={operation} />
              </S.TitleWrapper>
              {p.expanded && !p.hideTransaction && screenWidth > 560 && (
                <S.TransactionWrapper>
                  <CustomInternalLink
                    to={`/${environment}/transactions/${operation.transaction_hash}`}
                    title={operation.transaction_hash}
                  >
                    {shortenString(operation.transaction_hash, screenWidth < 920 ? 10 : 20)}
                  </CustomInternalLink>
                </S.TransactionWrapper>
              )}
              {p.expanded && !p.hideType && screenWidth > 680 && (
                <S.TypeWrapper>{operation.type}</S.TypeWrapper>
              )}
              {(screenWidth > 400 || !p.showJson) && (
                <S.TimeWrapper>
                  <CustomInternalLink to={`/${environment}/transactions/${operation.transaction_hash}`}>
                    <ReactTimeAgo date={new Date(operation.created_at)} locale="en-US" timeStyle="round" />
                  </CustomInternalLink>
                </S.TimeWrapper>
              )}
              {p.showJson && (
                <S.JsonWrapper>
                  <JsonButton type="operations" recordId={operation.id} />
                </S.JsonWrapper>
              )}
            </ListRow>
          );
        })}
      </ListContainer>
    </>
  );
};

export default OperationsTable;
