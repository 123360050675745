import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import CopyIconButton from "../../components/CopyIconButton/CopyIconButton";
import JsonButton from "../../components/JSONButton/JSONButton";
import LoaderWrapper from "../../components/LoaderWrapper/LoaderWrapper";
import {
  ContentContainer,
  FlexStartRow,
  Info,
  InfoPositioner,
  Table,
  TableHeader,
  TableRow,
  TablesContainer,
  Title,
  WrapperContainer,
} from "../../components/ReusableStyles";
import { useStellarContext } from "../../contexts/StellarContextProvider";
import { useGetAccount } from "../../data/services/useGetAccount";
import BottomPanel from "./components/BottomPanel/BottomPanel";

const Account = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const { screenWidth } = useStellarContext();

  const { t } = useTranslation();

  const { account, addressType, finalAccountId, isLoading, fetchingError } = useGetAccount(accountId);

  if (!account || isLoading || fetchingError)
    return <LoaderWrapper loading={isLoading} fetchingError={fetchingError} />;

  return (
    <WrapperContainer>
      <ContentContainer>
        <TableHeader>
          <FlexStartRow>
            <Title>{t("account")}</Title>
          </FlexStartRow>
          <JsonButton type="accounts" recordId={accountId} />
        </TableHeader>
        <TablesContainer>
          <Table>
            <TableRow>
              <Info>{t("key.public")}</Info>
              <InfoPositioner>
                <Info $smallerFont $maxWidthDisabled>
                  {account.id}
                </Info>{" "}
                <CopyIconButton value={account.id || ""} copiedInfoOnLeft={screenWidth < 1300} />
              </InfoPositioner>
            </TableRow>
            {addressType === "federated" && (
              <TableRow>
                <Info>{t("stellar.address")}</Info>
                <Info $smallerFont>{finalAccountId}</Info>
              </TableRow>
            )}
            <TableRow>
              <Info>{t("home.domain")}</Info>
              <Info>
                <a href={`https://${account.home_domain}`} target="_blank" rel="noreferrer">
                  {account.home_domain}
                </a>
              </Info>
            </TableRow>
            <TableRow $last>
              <Info>{t("subentry.count")}</Info>
              <Info>{account.subentry_count}</Info>
            </TableRow>
          </Table>
        </TablesContainer>
      </ContentContainer>
      <BottomPanel accountId={finalAccountId} account={account} />
    </WrapperContainer>
  );
};

export default Account;
