import { StrKey } from "@stellar/stellar-sdk";
import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import truncate from "lodash.truncate";

export const checkIfMuxedAddress = (publicKey: string) => StrKey.isValidMed25519PublicKey(publicKey);
export const isPublicKey = (publicKey: string) => StrKey.isValidEd25519PublicKey(publicKey);
export const shortenString = (string: string, length = 10) => truncate(string, { length });
export const isFederatedAddress = (address: string) => /^[^*,]*\*[a-z0-9-.]*$/i.test(address);
export const isSecretKey = (key: string) => StrKey.isValidEd25519SecretSeed(key);
export const isTransactionHash = (hash: string) => /^[0-9a-f]{64}$/i.test(hash);

export const base64DecodeToHex = (value: string) => Buffer.from(value, "base64").toString("hex");

export const getSourceAddress = (operation: ServerApi.OperationRecord) => {
  if (
    operation.type === "payment" ||
    operation.type === "path_payment_strict_send" ||
    operation.type === "path_payment_strict_receive"
  ) {
    return operation.from;
  }

  return operation.source_account;
};
