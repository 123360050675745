import styled from "styled-components";

export const MainContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.themeColors.backgrounds.contentBackground};
  padding: ${({ theme }) => `${theme.spacing(20)} ${theme.spacing(15)}`};
  width: 100%;
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(12)};
`;

export const TableHeader = styled.div<{ $alignItemsTop?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ $alignItemsTop }) => ($alignItemsTop ? "flex-start" : "center")};
  padding-bottom: ${({ theme }) => theme.spacing(15)};
  border-bottom: ${({ theme }) => theme.spacing(1)} solid
    ${({ theme }) => theme.palette.themeColors.borders.tableTitleBorderBottom};
  gap: ${({ theme }) => theme.spacing(20)};
`;

export const FlexStartRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(7.5)};
  min-width: 100px;
`;

export const Title = styled.p<{ $medium?: boolean }>`
  font-size: ${({ theme, $medium }) => ($medium ? theme.fontSize.base : theme.fontSize.m)};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

export const TableTitles = styled.div`
  display: flex;
  align-items: center;
  line-height: ${({ theme }) => theme.spacing(20)};
  border-bottom: ${({ theme }) => theme.spacing(1)} solid
    ${({ theme }) => theme.palette.themeColors.borders.tableTitleBorderBottom};
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  gap: ${({ theme }) => theme.spacing(5)};
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSize.base};
`;

export const ListRow = styled.div<{ $isLast?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 40px;
  gap: ${({ theme }) => theme.spacing(5)};

  ${({ theme, $isLast }) =>
    !$isLast &&
    `
       border-bottom: ${theme.spacing(0.7)} solid ${theme.palette.themeColors.borders.tableTitleBorderBottom}
  `};

  padding: ${({ theme }) => `${theme.spacing(5)} 0`};
`;

export const ChangePageButtonsContainer = styled.div<{ $page: number }>`
  display: flex;
  justify-content: ${({ $page }) => ($page > 1 ? "space-between" : "flex-end")};
  margin-top: ${({ theme }) => theme.spacing(6)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

export const TitleItemNumber = styled.p<{ $displayInline?: boolean }>`
  display: ${({ $displayInline }) => ($displayInline ? "inline" : "flex")};
  vertical-align: middle;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.palette.themeColors.fonts.secondaryTitle};
  gap: ${({ theme }) => theme.spacing(3)};

  min-width: 100px;

  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 20px;
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.palette.themeColors.fonts.error};
`;

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(20)};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.themeColors.backgrounds.contentBackground};
  padding: ${({ theme }) => `${theme.spacing(20)} ${theme.spacing(15)}`};
`;

export const TablesContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(15)};
  gap: ${({ theme }) => theme.spacing(30)};

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const Table = styled.div`
  width: 100%;
`;

export const TableRow = styled.div<{ $last?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: ${({ theme, $last }) => {
    if (!$last)
      return `
        1px solid ${theme.palette.themeColors.borders.tableTitleBorderBottom};
      `;
  }};
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(2)}`};
  gap: ${({ theme }) => theme.spacing(5)};
`;

export const InfoPositioner = styled.div`
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  max-width: 50%;
  gap: ${({ theme }) => theme.spacing(2.5)};

  @media (max-width: 1220px) {
    max-width: 40%;
    margin-right: ${({ theme }) => theme.spacing(10)};
  }
`;

export const Info = styled.p<{ $smallerFont?: boolean; $textOnRight?: boolean; $maxWidthDisabled?: boolean }>`
  display: inline;
  align-items: center;
  ${({ $maxWidthDisabled }) => (!$maxWidthDisabled ? "max-width: 50%" : "max-width: 100%")};
  gap: ${({ theme }) => theme.spacing(3)};
  ${({ theme, $smallerFont }) => $smallerFont && `font-size: ${theme.fontSize.base};`};
  ${({ $textOnRight }) => $textOnRight && "justify-content: flex-end"};

  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

export const FlexOneText = styled.p`
  display: flex;
  flex: 1;
  color: ${({ theme }) => theme.palette.themeColors.fonts.tableHeader};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  min-width: 60px;
`;

export const FlexOneWrapper = styled.div`
  flex: 1;
  min-width: 60px;
`;

export const TextWrap = styled.p`
  display: inline;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  top: 0;
  left: 0;
`;
